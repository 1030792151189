<template>
    <div class="flex items-center authbg justify-center">
        <Alert />
        <div class="w-50-l w-90">
            <!-- Simplebks logo -->
            <div class="tc pb4">
                <img src="../../../assets/images/simplebks-logo.png" style="width: 180px" alt="simplebks-logo" />
            </div>

            <div style="background-color: white;">
                <form
                    class="center pa5-l pa3"
                    @submit.prevent="signIn()"
                    :class="{ valid: !v$.$error && v$.$dirty, error: v$.$error }"
                >
                    <!-- <h2 class="tc" style="font-size: 1.5em">Hello Admin!</h2> -->
                    <div class="flex flex-column pb3">
                        <label class="pb2">Email</label>
                        <input class="form-sign" v-model="state.email" type="email" name="email" placeholder="beezo@amazon.com" />
                    </div>

                    <div class="input-errors mb2" v-for="error of v$.email?.$errors" :key="error.$uid">
                        <div class="error-msg">Email is required</div>
                    </div>

                    <div>
                        <div class="flex flex-column pb3">
                            <label class="pb2">Password</label>
                            <input
                                v-if="hidePassword"
                                class="form-sign"
                                v-model="state.password"
                                type="password"
                                placeholder="*************"
                            />
                            <input
                                v-if="!hidePassword"
                                class="form-sign"
                                v-model="state.password"
                                type="text"
                                placeholder="*************"
                            />
                        </div>
                        <font-awesome-icon
                            v-if="!hidePassword"
                            style="cursor: pointer"
                            @click="togglePassword"
                            class="field-icon"
                            icon="eye"
                        />
                        <font-awesome-icon
                            v-if="hidePassword"
                            style="cursor: pointer"
                            @click="togglePassword"
                            class="field-icon"
                            icon="eye-slash"
                        />
                        <div class="input-errors" v-for="error of v$.password?.$errors" :key="error.$uid">
                            <div class="error-msg">Password is required</div>
                        </div>
                    </div>

                    <!-- <div class="flex justify-between">
                        <label class="container">
                            <input type="checkbox" checked="checked" />Remember me
                            <span class="checkmark"></span>
                        </label>
                        <div></div>
                    </div> -->
                    <button class="mt3 w-100 submit-btn" type="submit" :disabled="$store.state.Admin.loginLoading">
                        {{ $store.state.Admin.loginLoading ? 'Processing' : 'Login' }}
                    </button>
                    <div class="pb3"></div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, computed, ref } from 'vue'
import { useStore } from 'vuex'
import Alert from '@/components/Alert'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
export default {
    components: { Alert },

    setup() {
        const store = useStore()
        // const message = computed(() => store?.state?.Auth?.message)
        const state = reactive({
            password: '',
            email: ''
        })
        const hidePassword = ref(true)

        const rules = {
            email: { required, email },
            password: { required }
        }

        const v$ = useVuelidate(rules, state)

        const signIn = () => {
            v$.value.$touch()
            if (v$.value.$invalid) return

            store.dispatch('Admin/Login', {
                email: state.email.toLocaleLowerCase(),
                password: state.password
            })
        }

        const togglePassword = () => {
            hidePassword.value = !hidePassword.value
        }

        return {
            state,
            signIn,
            // message,
            hidePassword,
            v$,
            togglePassword
        }
    }
}
</script>

<style>
.input-group {
    position: relative;
}
.input-group-label {
    position: absolute;
    top: 16px;
    right: 15px;
    cursor: pointer;
}
.fa-2x {
    font-size: 1.3em;
}

.authbg {
    background-image: url('../../../assets/images/login-bg.jpg') !important;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    height: 100vh;
    /* max-height: 20rem; */
    width: 100% !important;
    z-index: 1;
}

.error-msg {
    color: red;
}

.field-icon {
    float: right;
    margin-left: -28px;
    margin-top: -42px;
    position: relative;
    z-index: 2;
    margin-right: 15px;
}
</style>
